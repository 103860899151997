import React, { useEffect, useState } from 'react';

//component
import BackgroundWrapper from "../components/BackgroundWrapper";
import Loading from "../components/Loading"

//from
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import randomBytes from "randombytes"

//icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { default as swal } from 'sweetalert2'

import goldenLogo from '../assets/images/goldennet_logo.svg';

import axios from 'axios';
import url from 'url';
import qs from 'qs';
import jwt from 'jsonwebtoken';

const Login = () => {
    const state = randomBytes(32).toString('hex');
    const nonce = randomBytes(32).toString('hex');

    const lineLogin = () => {
        let link = 'https://access.line.me/oauth2/v2.1/authorize?';
        link += 'response_type=code';
        link += `&client_id=${process.env.REACT_APP_LINE_LOGIN_CHANNEL_ID}`;
        link += `&redirect_uri=${process.env.REACT_APP_LINE_LOGIN_CALLBACK_URL}`;
        link += `&state=${state}`;
        link += `&nonce=${nonce}`;
        link += '&scope=openid%20profile';
        link += '&bot_prompt=normal';
        window.location.href = link;
    };
    useEffect(() => {
        var urlParts = url.parse(window.location.href, true);
        var query = urlParts.query;
        const isLogin = () => {
            if (Object.keys(query).length === 0) {
                lineLogin();
            }
        }
        isLogin()
    });

    const getAccessToken = (callbackURL) => {
        var urlParts = url.parse(callbackURL, true);
        var query = urlParts.query;
        var hasCodeProperty = Object.prototype.hasOwnProperty.call(query, 'code');
        if (hasCodeProperty) {
            const reqBody = {
                grant_type: 'authorization_code',
                code: query.code,
                redirect_uri: `${process.env.REACT_APP_LINE_LOGIN_CALLBACK_URL}`,
                client_id: `${process.env.REACT_APP_LINE_LOGIN_CHANNEL_ID}`,
                client_secret: `${process.env.REACT_APP_LINE_LOGIN_CHANNEL_SECRET}`
            };
            const reqConfig = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            };
            axios
                .post(
                    'https://api.line.me/oauth2/v2.1/token',
                    qs.stringify(reqBody),
                    reqConfig
                )
                .then((res) => {
                    try {
                        const decodedIdToken = jwt.verify(res.data.id_token, `${process.env.REACT_APP_LINE_LOGIN_CHANNEL_SECRET}`, {
                            algorithms: ['HS256'],
                            audience: `${process.env.REACT_APP_LINE_LOGIN_CHANNEL_ID}`.toString(),
                            issuer: 'https://access.line.me',
                            // nonce: `${newId}`
                        });
                        if (localStorage.getItem('page') == '/registration') {
                            document.cookie = `lineIdToken=${decodedIdToken.sub};`;
                            setTimeout(() => { window.location.href = `/registration?id=${sessionStorage.getItem('caseId')}` }, 1000);
                        } else {
                            // setLineId(decodedIdToken.sub)
                            lineCanLogin(decodedIdToken.sub);
                            document.cookie = `lineIdToken=${decodedIdToken.sub};`;
                        }

                    } catch (err) {
                        // If token is invalid.
                        console.log(err);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else if (query.error) {
            swal.fire({
                icon: 'error',
                title: '登入失敗',
                text: '需「許可」授權，才能驗證登入',
                confirmButtonText: '重新登入',
                allowOutsideClick: false
            }).then(function () {
                lineLogin()
            })
        }
    };
    useEffect(() => {
        getAccessToken(window.location.href)
    }, []);

    const validationSchema = Yup.object().shape({
        uid: Yup.string()
            .required('請正確輸入帳號'),
        // .email('Email is invalid'),
        pwd: Yup.string()
            // .min(6, 'Password must be at least 6 characters')
            .required('請正確輸入密碼'),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    let [loadingBtn, setLoadingBtn] = useState(false)
    //頁面跳轉中 loading
    let [isLoading, setIsLoading] = useState(false)
    //等待中 loading
    let [bigLoading, setBigLoading] = useState(true)

    //判斷 line 登入權限
    const lineCanLogin = (id) => {
        setBigLoading(true)
        let params = new URLSearchParams();
        params.append('lineid', id);
        let API = `${process.env.REACT_APP_GOLDEN_API5000}LineLogin`
        /* 0:驗證成功 -2:帳號停用中 -3:不在使用權限內，需進行業管帳密登入驗證 -6:帳號未繳費 */
        axios.post(API, params)
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '0') {
                        setBigLoading(false)
                        setIsLoading(true)
                        const { JWT, Token } = res.data;
                        document.cookie = `iSmartToken=${JWT};`;
                        document.cookie = `MyToken=${Token};`;
                        document.cookie = `userDataToken=${escape(JSON.stringify(res.data))};`;
                        document.cookie = `lineIdToken=${id};`;
                        if (localStorage.getItem('page') === null) {
                            setIsLoading(true)
                            sessionStorage.setItem('state', res.data.ResponseCode)
                            setTimeout(() => { window.location.href = '/ismart_state' }, 1000);
                        } else {
                            setIsLoading(true)
                            setTimeout(() => { window.location.href = localStorage.getItem('page') }, 1000);
                        }
                    } else if (res.data.ResponseCode === '-2' || res.data.ResponseCode === '-6') {
                        setBigLoading(false)
                        setIsLoading(true)
                        document.cookie = `iSmartToken=;`;
                        document.cookie = `MyToken=;`;
                        document.cookie = `userDataToken=;`;
                        document.cookie = `lineIdToken=;`;
                        sessionStorage.setItem('state', res?.data?.ResponseCode)
                        setTimeout(() => { window.location.href = '/ismart_state' }, 1000);
                    } else if (res.data.ResponseCode === '-3') {
                        setBigLoading(false)
                        setIsLoading(false)
                        swal.fire({
                            icon: 'info',
                            html: `
                            <div>
                                <div class='text-center fw-bolder'>
                                    <h5 class='fw-bolder'>請進行登入業管系統《帳號、密碼》進行綁定</h5>
                                    <p class='mt-3'>不在使用權限內</p>
                                </div>
                            </div>`,
                            confirmButtonText: '關閉進行登入',
                            allowOutsideClick: false
                        })
                    } else {
                        alert(`LineLogin 綁定失敗 (${res?.data?.ResponseCode})`)
                        setBigLoading(false)
                        setIsLoading(true)
                        document.cookie = `iSmartToken=;`;
                        document.cookie = `MyToken=;`;
                        document.cookie = `userDataToken=;`;
                        document.cookie = `lineIdToken=;`;
                        sessionStorage.setItem('state', res?.data?.ResponseCode)
                        setTimeout(() => { window.location.href = '/ismart_state' }, 1000);
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                console.log(err)
                alert('LineLogin:請重新整理(err)')
                window.location.href = '/login'
            })
    }

    const onSubmit = (data) => {
        //@ 檢查最後碼
        let accId = data.uid.substr(-1), //取帳號最後一碼
            enText = new RegExp("[A-Za-z]+")
        if (enText.test(accId)) {
            swal.fire({
                icon: 'info',
                html: `
                <div>
                    <div class='text-center fw-bolder'>
                        <h5 class='fw-bolder'>請使用業務員身分登入</h5>
                    </div>
                </div>`,
                confirmButtonText: '關閉',
                allowOutsideClick: false
            })
            return
        }

        const lineId = document.cookie.replace(/(?:(?:^|.*;\s*)lineIdToken\s*=\s*([^;]*).*$)|^.*$/, '$1');
        setEye(true);
        setLoadingBtn(true)
        let salesLoginData = new URLSearchParams();
        salesLoginData.append('lineid', lineId);
        salesLoginData.append('uid', data.uid.trim());
        salesLoginData.append('pwd', data.pwd);

        let API = `${process.env.REACT_APP_GOLDEN_API5000}SalesLogin`
        // axios.default.withCredentials = true;
        axios.post(API, salesLoginData, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '0') {
                        setBigLoading(false)
                        setIsLoading(true)
                        const { JWT, Token } = res.data;
                        document.cookie = `iSmartToken=${JWT};`;
                        document.cookie = `MyToken=${Token};`;
                        document.cookie = `userDataToken=${escape(JSON.stringify(res.data))};`;
                        if (localStorage.getItem('page') === null) {
                            setIsLoading(true)
                            sessionStorage.setItem('state', res.data.ResponseCode)
                            setTimeout(() => { window.location.href = '/ismart_state' }, 1000);
                        } else {
                            setIsLoading(true)
                            setTimeout(() => { window.location.href = localStorage.getItem('page') }, 1000);
                        }
                        reset();
                    } else if (res.data.ResponseCode === '-4') {
                        setLoadingBtn(false)
                        swal.fire({
                            icon: 'info',
                            title: '此帳號不存在',
                            text: '請確認帳號是否輸入正確',
                            confirmButtonText: '關閉',
                            allowOutsideClick: false
                        })
                    } else if (res.data.ResponseCode === '-5') {
                        setLoadingBtn(false)
                        swal.fire({
                            icon: 'error',
                            title: '密碼錯誤',
                            text: '請確認密碼是否輸入正確',
                            confirmButtonText: '關閉',
                            allowOutsideClick: false
                        })
                    } else {
                        setBigLoading(false)
                        alert(`登入錯誤，請聯繫管理員(SalesLogin:${res?.data?.ResponseCode})`)
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                console.log(err)
                setBigLoading(false)
                alert(`網路連線錯誤，請聯繫管理員(SalesLogin:err)`)
                reset();
            })
    }
    //密碼眼睛
    let [eye, setEye] = useState(true);
    const handleEyeClick = () => {
        if (eye) {
            setEye(false);
        } else {
            setEye(true);
        }
    }
    return (
        <div>
            <Loading isLoading={bigLoading} circleHide={false}></Loading>
            <Loading isLoading={isLoading} circleHide={true}>
                <h5 className={`text-center bg-golden-brown p-2 rounded goto-page pe-5 fw-bolder`}
                    data-text="•••">頁面跳轉中</h5>
            </Loading>
            <BackgroundWrapper>
                <div className="container-fluid bg-mask vh-100">
                    <div className="container">
                        <div className="text-center py-5"><img src={goldenLogo} className="img-fluid" alt="Golden-LOGO" width="300px" /></div>
                        {/* <h4 className="text-center fw-bolder text-golden-brown">iSmart 登入</h4> */}
                        <div className="d-flex justify-content-center">
                            <form onSubmit={handleSubmit(onSubmit)} className="col-12 col-md-6 col-lg-4">
                                <div className="form-group my-3">
                                    <label htmlFor="uid" className="fw-bolder text-golden-brown">請輸入《業管系統》帳號</label>
                                    <input id="uid" name="uid" type="text" {...register('uid')} className={`form-control ${errors.uid ? 'is-invalid' : ''}`}
                                    />
                                    <div className="invalid-feedback">{errors.uid?.message}</div>
                                </div>
                                <div className="form-group my-3 position-relative">
                                    <label htmlFor="pwd" className="fw-bolder text-golden-brown">請輸入《業管系統》密碼</label>
                                    <input id="pwd" name="pwd" type={eye ? 'password' : 'text'} className={`form-control ${errors.pwd ? 'is-invalid' : ''}`}
                                        {...register('pwd')}
                                    />
                                    <div className="invalid-feedback">{errors.pwd?.message}</div>
                                    <button type="button" className="btn text-dark-blue position-absolute" style={{ right: '-1px', top: '24px' }} onClick={handleEyeClick}><FontAwesomeIcon icon={eye ? faEye : faEyeSlash} /></button>
                                </div>
                                <button type="submit" className="btn btn-golden-brown w-100 mt-3" disabled={loadingBtn ? 'disabled' : ''}>
                                    <div className={`spinner-border text-light spinner-border--width mx-2 ${loadingBtn ? 'd-inline-block' : 'd-none'}`} style={{ width: '1rem', height: '1rem' }} role="status" ></div>
                                    登入
                                </button>
                                {/* <button type="button" onClick={() => reset()} className="btn btn-secondary">Reset</button> */}
                            </form>
                        </div>
                    </div>
                </div>
            </BackgroundWrapper>
        </div>
    );
};

export default Login
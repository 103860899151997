import React, { useState, useEffect } from 'react';

import BackgroundWrapper from '../components/BackgroundWrapper';
import goldennetLogo from "../assets/images/goldennet_logo.svg";

const StatePage = () => {
    let style = {
        position: 'absolute',
        top: '35%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%'
    }

    let [stateText, setStateText] = useState('');
    useEffect(() => {
        if (sessionStorage.getItem('state') === '0') {
            setStateText('登入成功!! 歡迎使用 iSmart+ ~\n請返回 Line 選單，重新點選功能')
        } else if (sessionStorage.getItem('state') === '-2') {
            setStateText('您的帳號已停用!!如有疑問洽詢')
        } else if (sessionStorage.getItem('state') === '-6') {
            setStateText('您的帳號尚未繳費!!如有疑問洽詢')
        } else if (sessionStorage.getItem('state') === null) {
            setStateText('請返回 Line 選單，點選功能~')
        } else {
            setStateText(`您的帳號綁定失敗(${sessionStorage.getItem('state')})`)
        }
    }, []);

    return (
        <BackgroundWrapper>
            <div style={style}>
                <div className="text-center py-5"><img src={goldennetLogo} className="img-fluid" alt="Golden-LOGO" width="300px" /></div>
                <h3 className="fw-bolder text-golden-brown text-center" style={{ whiteSpace: 'break-spaces' }}>
                    {stateText}
                </h3>
            </div>
        </BackgroundWrapper>
    )
}
export default StatePage
import React, { useState, useEffect, useRef } from 'react';
import { ConfigProvider, Pagination, Table } from 'antd';
import Loading from 'components/Loading';
import axios from 'axios';
import AlertMes from 'components/Alert';
import moment from 'moment';
import PageTitle from 'Dashboard/components/PageTitle';
import StepList from 'components/StepList';
import * as xlsx from 'xlsx';
import { handleStepData } from 'assets/javascript/otherTool/chubbPizza';

const ChubbPizza = (props) => {
    let [isLoading, setIsLoading] = useState(false),
        [alertMes, setAlertMes] = useState({ mes: '', show: false, color: 'transparent' });
    let [fileData, setFileData] = useState(null),
        [allData, setAllData] = useState([]),
        [pageData, setPageData] = useState(null),
        [currentPage, setCurrentPage] = useState(1),
        [chooseDataList, setChooseDataList] = useState([]);

    let APIRef = useRef(null);

    //@ TABLE
    const handleTable = {
        columns: [
            {
                title: '推播狀態',
                dataIndex: 'CP_PushState',
                align: 'center',
                width: '150px',
                render: (_, record) => <div>{record?.CP_PushState === 0 ? <span className='text-gray-600'>未推播</span> : <span className='text-dark-blue'>已推播</span>}</div>,
            },
            {
                title: '客戶姓名',
                dataIndex: 'CP_PayerCodeName',
                align: 'center',
            },
            {
                title: '業務姓名',
                dataIndex: 'CP_HandleName',
                align: 'center',
            },
            {
                title: '匯入時間',
                dataIndex: 'CP_ChubbDate',
                align: 'center',
                render: (_, record) => <div>{moment(record.CP_ChubbDate).format('YYYY-MM-DD')}</div>,
            },
        ],
    };

    //@ API
    const handleAPI = {
        getAllData: () => {
            setIsLoading(true);
            let API = `${process.env.REACT_APP_GOLDEN_NPS_API}get_chubb_pizza_all_list?code=0&page=${currentPage}&pageSize=10&total=46530`;

            axios
                .get(API)
                .then((res) => {
                    let { code, data, page, pageSize, total } = res.data;
                    setIsLoading(false);
                    if (code === 0) {
                        setAllData(data);
                        setPageData({ page, pageSize, total });
                    }
                })
                .catch((err) => {
                    setIsLoading(false);
                    alert('網路連線錯誤，請通知管理員(err)');
                    console.log(err);
                });
        },
        insertFile: () => {
            setIsLoading(true);
            let API = `${process.env.REACT_APP_GOLDEN_NPS_API}insert_chubb_pizza `;

            //-- 處理 post data
            let postData = [];
            fileData.forEach((item) => {
                postData.push({
                    CP_Handle: item.Handle,
                    CP_PayerCode: item.PayerCode,
                    CP_ChubbDate: moment().format('YYYY-MM-DD'),
                });
            });

            axios
                .post(
                    API,
                    {
                        dataArray: postData,
                    },
                    { headers: { 'content-type': 'application/json' } },
                    { timeout: 1000000 },
                )
                .then((_) => {
                    setIsLoading(false);
                    setFileData(null);
                })
                .catch((err) => {
                    setIsLoading(false);
                    alert('網路連線錯誤，請通知管理員(err)');
                    console.log(err);
                });
        },
        updatePushState: (data, errDataList) => {
            setIsLoading(true);
            let API = `${process.env.REACT_APP_GOLDEN_NPS_API}update_chubb_pizza`;
            let postData = data
                .filter((item) => !errDataList.some((errItem) => item.CP_Handle === errItem.Sales_LineID && item.CP_PayerCodeName === errItem.Cust_name))
                .map((mapItem) => {
                    return {
                        CP_MST_ID: mapItem.CP_MST_ID,
                        CPD_MST_ID: mapItem.CPD_MST_ID,
                        CP_S_MODE: '1',
                        CP_Data: moment().format('YYYY-MM-DD'),
                    };
                });

            axios
                .post(API, postData)
                .then((_) => {
                    setIsLoading(false);
                    setPageData(null);
                    setChooseDataList([])
                    APIRef.current.getAllData();
                })
                .catch((err) => {
                    setIsLoading(false);
                    console.log(err);
                    alert('網路連線錯誤，請聯繫管理員(err)');
                });
        },
        dailyPushCust: (data) => {
            setIsLoading(true);
            let API = `${process.env.REACT_APP_GOLDEN_DailyReport_API}Daily/PushCust`;

            let postData = data.map((item) => {
                return {
                    Sales_LineID: item.CP_Handle,
                    Sales_name: item.CP_HandleName,
                    Cust_name: item.CP_PayerCodeName,
                };
            });

            axios
                .post(API, { DataList: postData })
                .then((res) => {
                    setIsLoading(false);
                    let { statusCode, ErrDataList, errMsgs } = res.data;
                    if (statusCode === '0') {
                        handleAPI.updatePushState(data, ErrDataList);
                        setAlertMes({ mes: '已推播成功', show: true, color: 'primary' })
                    } else {
                        alert(errMsgs);
                    }
                })
                .catch((err) => {
                    setIsLoading(false);
                    console.log(err);
                    alert('網路連線錯誤，請聯繫管理員(err)');
                });
        },
    };
    APIRef.current = handleAPI;

    //@ EVENT
    const handleEvent = {
        importFile: (e) => {
            const file = e.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    const binaryStr = event.target.result;
                    const workbook = xlsx.read(binaryStr, { type: 'binary' });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const jsonData = xlsx.utils.sheet_to_json(worksheet);
                    setFileData(jsonData);
                };
                reader.readAsBinaryString(file);
            }
        },
    };

    //@ UI
    const handleUI = {
        Step: (data) => {
            let { firstNullIndex, finalStepData } = handleStepData(data);
            return <StepList stepNum={firstNullIndex + 1} data={finalStepData} />;
        },
    };

    useEffect(() => {
        if (!!fileData) {
            APIRef.current.insertFile();
        }
    }, [fileData]);

    useEffect(() => {
        APIRef.current.getAllData();
    }, [currentPage]);

    useEffect(() => {
        APIRef.current.getAllData();
    }, []);

    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        defaultActiveColor: '#315176',
                    },
                    Table: {
                        rowSelectedBg: '#ddeaf9',
                        rowSelectedHoverBg: '#ddeaf9',
                    },
                },
            }}>
            <AlertMes mes={alertMes.mes} show={alertMes.show} color={alertMes.color} setAlertMes={setAlertMes} />
            <Loading isLoading={isLoading} />
            <PageTitle data={props} />
            {!!pageData && (
                <div className='h-100 fw-bolder'>
                    <div className='container px-4'>
                        <div className='d-flex align-items-center'>
                            <button className='btn btn-primary fw-bolder mx-3' onClick={(e) => handleAPI.dailyPushCust(chooseDataList)} disabled={chooseDataList.length === 0 ? true : false}>
                                推播圖卡 {chooseDataList?.length > 0 && `(已選取${chooseDataList?.length}筆)`}
                            </button>
                            <div className='upload-btn-warp'>
                                <input id='upload' type='file' accept='.xlsx, .xls, .csv' onChange={(e) => handleEvent?.importFile(e)} />
                                <label type='button' htmlFor='upload' className='btn btn-dark-blue add-btn py-1 px-2'>
                                    <span className='ms-2'>匯入客戶</span>
                                </label>
                            </div>
                        </div>
                        <div className='mt-3'>
                            <Table
                                rowSelection={{
                                    type: 'checkbox',
                                    onChange: (_, selectedRows) => {
                                        setChooseDataList(selectedRows);
                                    },
                                    getCheckboxProps: (record) => ({
                                        disabled: record?.CP_PushState === 0 ? false : true,
                                    }),
                                }}
                                columns={handleTable?.columns}
                                dataSource={allData}
                                rowKey={(record) => record.CP_MST_ID}
                                expandable={{
                                    expandedRowRender: (record) => handleUI.Step(record),
                                }}
                                pagination={false}
                            />
                            <div className='my-4'>
                                <Pagination align='center' defaultCurrent={1} total={pageData?.total} current={currentPage} onChange={(val) => (setCurrentPage(val), setChooseDataList([]))} showSizeChanger={false} />
                            </div>
                        </div>
                    </div>
                    <div>{!!fileData && <pre>{JSON.stringify(fileData, null, 2)}</pre>}</div>
                </div>
            )}
        </ConfigProvider>
    );
};
export default ChubbPizza;

import React, { useEffect, useState, useRef } from 'react';
import { ConfigProvider, Flex, Radio, Collapse, DatePicker, Button, InputNumber, Alert, Form } from 'antd';
import Loading from 'components/Loading';
import Logo from 'assets/images/chubbPizza/ChubbPizza.svg';
import axios from 'axios';
import { handleStepData } from 'assets/javascript/otherTool/chubbPizza';
import moment from 'moment';
import Countfunc from 'assets/javascript/countfunc';
import LineLogin from 'assets/javascript/lineLogin';
import StepList from 'components/StepList';
const ChubbPizza = () => {
    let uiRef = useRef(null),
        APIRef = useRef(null);

    let [form] = Form.useForm();
    let [isLoading, setIsLoading] = useState(false);

    let [allData, setAllData] = useState([]),
        [collapseData, setCollapseData] = useState([]),
        [userData, setUserData] = useState(null);

    //@ API
    const handleAPI = {
        getList: (targetClientNum) => {
            setIsLoading(true);
            //-- 取得客戶名單
            let API = `${process.env.REACT_APP_GOLDEN_NPS_API}get_chubb_pizza_list/${userData?.OLD_ID}`;
            axios
                .get(API)
                .then((res) => {
                    setIsLoading(false);
                    let { code, data } = res.data;
                    if (code === 0) {
                        if (!!targetClientNum) {
                            //表示有送出更新
                            setCollapseData([]);
                            handleAPI.getDetail([targetClientNum], data);
                        } else {
                            //表示第一此取得
                            setAllData(data);
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                    alert('網路連線錯誤，請通知管理員(err)');
                    setIsLoading(false);
                });
        },
        getDetail: (val, allData) => {
            //-- 取得客戶明細
            if (val?.length === 0) {
                return;
            } else {
                setIsLoading(true);
                let API = `${process.env.REACT_APP_GOLDEN_NPS_API}get_chubb_pizza_detail/${val[0]}`;
                axios
                    .get(API)
                    .then((res) => {
                        setIsLoading(false);
                        let { code, data } = res.data;
                        if (code === 0) {
                            let updatedData = [...allData];
                            setAllData(
                                updatedData.map((item) => {
                                    if (val[0] == item.CP_MST_ID) {
                                        item.stepData = data[0];
                                    }
                                    return item;
                                }),
                            );
                        }
                    })
                    .catch((err) => {
                        setIsLoading(false);
                        console.log(err);
                    });
            }
        },
        updatePushState: (postData) => {
            setIsLoading(true);
            let API = `${process.env.REACT_APP_GOLDEN_NPS_API}update_chubb_pizza`;
            axios
                .post(API, postData)
                .then((res) => {
                    setIsLoading(false);
                    form.resetFields();
                    handleAPI.getList([postData[0].CP_MST_ID]);
                })
                .catch((err) => {
                    setIsLoading(false);
                    console.log(err);
                    alert('網路連線錯誤，請聯繫管理員(err)');
                });
        },
    };
    APIRef.current = handleAPI;

    //@ EVENT
    const handleEvent = {
        changeStep: (item, val) => {
            let { value } = val.target;
            let newData = allData.map((oItem) => {
                if (oItem.CP_MST_ID === item.CP_MST_ID) {
                    oItem.checkLevel = value;
                }
                return oItem;
            });
            setAllData(newData);
        },
    };

    const handleForm = {
        Submit: (userData, step) => {
            function getData(step) {
                const thisMode = step.split('S')[1];
                const thisData = form.getFieldValue(`${userData.CP_MST_ID}_${step}`);

                switch (step) {
                    case 'S1':
                    case 'S2':
                    case 'S3':
                    case 'S4':
                        return {
                            mode: thisMode,
                            data: moment(thisData).format('YYYY-MM-DD'),
                        };
                    case 'S5':
                        return {
                            mode: thisMode,
                            data: thisData,
                        };
                    default:
                        return {
                            mode: '',
                            data: '',
                        };
                }
            }

            if (!!form.getFieldValue(`${userData.CP_MST_ID}_${step}`)) {
                let { mode, data } = getData(step);
                let postData = [
                    {
                        CP_MST_ID: userData?.CP_MST_ID,
                        CPD_MST_ID: userData?.stepData?.CPD_MST_ID,
                        CP_S_MODE: mode,
                        CP_Data: data,
                    },
                ];

                handleAPI.updatePushState(postData);
            } else {
                alert(`尚未填寫【客戶：${userData?.CP_PayerCodeName}】的資料`);
            }
        },
        onFinishFailed: (errorInfo) => {
            console.log('Failed:', errorInfo);
        },
    };

    //@ UI
    const handleUI = {
        stepRadio: (data) => {
            let { firstNullIndex, finalStepData } = handleStepData(data?.stepData);
            let defaultVal = `S${firstNullIndex + 1}`;
            data.defaultStep = defaultVal;

            return (
                <>
                    <Flex vertical gap='middle'>
                        <Radio.Group
                            block
                            options={finalStepData.map(({ step, text, ...rest }) => ({
                                key: `${data?.CP_MST_ID}_${step}`,
                                label: step,
                                value: step,
                                ...rest,
                            }))}
                            id={data?.CP_MST_ID}
                            defaultValue={defaultVal}
                            optionType='button'
                            buttonStyle='solid'
                            onChange={(val) => handleEvent.changeStep(data, val)}
                        />
                    </Flex>
                    {!!data?.stepData &&
                        finalStepData
                            .filter((val) => {
                                return !!data?.checkLevel ? val.step === data?.checkLevel : val.step === defaultVal;
                            })
                            .map((item) => {
                                if (item.text === '進行中') {
                                    return handleUI.stepInput(data, defaultVal);
                                } else if (item.text === '尚未開始') {
                                    return <></>;
                                } else {
                                    return handleUI.stateDoneBox(item.step, item.text);
                                }
                            })}
                </>
            );
        },
        stepInput: (data, defaultStep) => {
            let stepVal = !!data?.checkLevel ? data?.checkLevel : defaultStep;
            function inputWrap(step) {
                switch (step) {
                    case 'S1':
                        return <></>;
                    case 'S2':
                    case 'S3':
                    case 'S4':
                        return (
                            <Form.Item name={`${data?.CP_MST_ID}_${stepVal}`}>
                                <DatePicker className='w-100' size='large' />
                            </Form.Item>
                        );
                    case 'S5':
                        return (
                            <Form.Item name={`${data?.CP_MST_ID}_${stepVal}`}>
                                <InputNumber placeholder='輸入受理編號' size='large' className='w-100' />
                            </Form.Item>
                        );
                    default:
                        break;
                }
            }

            return (
                <Form name={data?.CP_MST_ID} form={form} initialValues={{}} layout='vertical' autoComplete='on'>
                    <div className='my-3'>{inputWrap(stepVal)}</div>
                    <div className='text-end'>
                        <Button color='default' variant='solid' type='primary' onClick={(_) => handleForm.Submit(data, defaultStep)}>
                            確認送出
                        </Button>
                    </div>
                </Form>
            );
        },
        stateDoneBox: (level, text) => {
            switch (level) {
                case 'error':
                    return (
                        <Alert
                            message={<span style={{ color: '#b40000' }}>取得明細錯誤</span>}
                            description={
                                <span className='text-gray-600' style={{ fontSize: '16px' }}>
                                    {text}
                                </span>
                            }
                            type='error'
                            showIcon
                        />
                    );
                case 'S6':
                    return (
                        <p className='mt-3' style={{ fontSize: '18px' }}>
                            受理狀態：<span className='text-pink'>{text.replace('受理狀態', '')}</span>
                        </p>
                    );
                default:
                    return (
                        <div className='mt-3'>
                            <Alert
                                message={<span style={{ color: '#008c46' }}>已完成</span>}
                                description={
                                    <span className='text-gray-600' style={{ fontSize: '16px' }}>
                                        {text}
                                    </span>
                                }
                                type='success'
                                showIcon
                            />
                        </div>
                    );
            }
        },
    };
    uiRef.current = handleUI;

    useEffect(() => {
        // 登入驗證
        const token = document.cookie.replace(/(?:(?:^|.*;\s*)userDataToken\s*=\s*([^;]*).*$)|^.*$/, '$1');
        if (token === '') {
            localStorage.setItem('page', window.location.pathname);
            window.location.href = '/login';
        } else {
            setUserData(JSON?.parse(unescape(token)));
        }
    }, []);

    useEffect(() => {
        LineLogin(window.location.pathname);
        Countfunc('安達必勝客', 58);
        setIsLoading(true);
        let API = `${process.env.REACT_APP_GOLDEN_API5000}CheckState`;
        axios
            .get(API, {
                withCredentials: true,
                headers: { 'Access-Control-Allow-Credentials': 'true' },
            })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', window.location.pathname);
                        window.location.href = '/login';
                        return;
                    } else {
                        setIsLoading(false);
                        if (!!userData?.OLD_ID) {
                            APIRef.current.getList();
                        }
                    }
                }, Number(process.env.REACT_APP_TIMEOUT));
            })
            .catch((err) => {
                console.log(err);
                // alert('請重新整理')
            });
    }, [userData]);

    useEffect(() => {
        if (allData.length >= 0) {
            let newCollapseData = allData.map((item) => {
                return {
                    key: item?.CP_MST_ID,
                    label: <span style={{ fontSize: '16px' }}>{item?.CP_PayerCodeName}</span>,
                    value: item?.CP_MST_ID,
                    children: <>{!!item.stepData ? <>{uiRef.current.stepRadio(item)}</> : uiRef.current.stateDoneBox('error', '')}</>,
                };
            });

            setCollapseData(newCollapseData);
        }
    }, [allData]);

    return (
        <>
            <Loading isLoading={isLoading} />
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#F11A7B',
                        colorSuccess: '#008c46',
                        colorSuccessBg: '#edfff6',
                        colorSuccessBorder: '#1fcc75',
                    },
                    components: {
                        Collapse: {
                            headerBg: '#fff',
                        },
                        Alert: {
                            withDescriptionPadding: '12px 16px',
                        },
                        Pagination: {
                            itemBg: 'transparent',
                            itemActiveBg: '#f2e6eb',
                        },
                    },
                }}>
                <div className='bg-gray' style={{ minHeight: '100vh' }}>
                    <div className='bg-pink py-4 text-center'>
                        <img src={Logo} alt='安達必勝客' />
                    </div>
                    <div className='container py-4 fw-bolder'>
                        <div className='row'>
                            {allData?.length > 0 ? (
                                <div className='col-md-8 col-lg-6 mx-auto'>
                                    <div>
                                        <h1 className='fw-bolder' style={{ fontSize: '24px' }}>
                                            客戶列表
                                        </h1>
                                        <p className='text-gray-600 my-3'>共 {allData?.length} 筆結果</p>
                                    </div>
                                    {allData?.length <= 0 ? (
                                        <div className='col-sm-6 col-md-4 mx-auto'>
                                            <div className='mt-3 text-center'>
                                                <Alert message='尚無目標客戶' description={<span style={{ fontSize: '16px' }}></span>} type='info' showIcon />
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <div className='bg-gray pt-2 pb-1' style={{ position: 'sticky', top: '0px', zIndex: 1 }}>
                                                <StepList stepNum={0}
                                                    data={[
                                                        { step: 'S1', text: '取得名單' },
                                                        { step: 'S2', text: '約訪' },
                                                        { step: 'S3', text: '初步面談' },
                                                        { step: 'S4', text: '確認需求' },
                                                        { step: 'S5', text: '提交規劃書' },
                                                        { step: 'S6', text: '成交' }
                                                    ]} />
                                            </div>
                                            <div className='my-2'>
                                                <Collapse accordion destroyInactivePanel={true} onChange={(val) => handleAPI.getDetail(val, allData)} items={collapseData} />
                                            </div>
                                        </>
                                    )}
                                </div>
                            ) : (
                                <div className='col-sm-6 col-md-4 mx-auto'>
                                    <div className='mt-3 text-center'>
                                        <Alert message='尚無目標客戶' description={<span style={{ fontSize: '16px' }}></span>} type='info' showIcon />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </ConfigProvider>
        </>
    );
};
export default ChubbPizza;
